import React, { useState, useRef, useEffect } from 'react';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import './App.css';
import Heatmap from './Heatmap';
import { KnowledgeObject, UserInfo } from './types';

function App() {
    const apiKey = useRef<string>(null)
    const [radical, setRadical] =useState<KnowledgeObject[]>([])
    const [kanji, setKanji] =useState<KnowledgeObject[]>([])
    const [vocabulary, setVocabulary] =useState<KnowledgeObject[]>([])
    const [usetInfo, setUserInfo] = useState<UserInfo | null>(null)

    const fetchWanikaniData = async () => {
        if(!apiKey.current) return
        const header = `Authorization: Bearer ${apiKey.current}`
        const instance = axios.create({
            baseURL: 'https://api.wanikani.com',
            headers: { header }
        })
        const userInfoFetched: AxiosResponse = await instance.get(`/v2/user`)
        if(userInfoFetched.status === 200){
            setUserInfo({
                username: userInfoFetched.data.data.username,
                level: userInfoFetched.data.data.level
            })
        }        
        
    }

    return (
        <div className="App">
            <div style={{padding: '5px'}}>
                <h2>Your wanikani v2 API Key :</h2>
                <input type='text'onChange={e => apiKey.current = e.target.value}/>
                <button onClick={fetchWanikaniData}>Load my data !</button>
            </div>
            <div>{usetInfo}</div>
        </div>
    );
}

export default App;
